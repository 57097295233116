<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                   <ion-button @click="$router.go(-1)">
                        <ion-img :src="require('@/assets/Icons/arrow-back-grey.svg')" class="w-6 h-6"></ion-img>
                    </ion-button>
                </ion-buttons>
                <ion-title><h1 class="text-base text-gray-400 text-center font-semibold">Notifications</h1></ion-title>
                <ion-buttons slot="end">
                    <ion-button router-link="/home/settings">
                        <ion-img :src="require('@/assets/Icons/swap-reject-circle-solid-grey.svg')" class="w-6 h-6"></ion-img>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true">
            <ion-card class="mx-7.5 shadow-none">
                <h2 class="text-md text-smcc-grey-dark font-bold">Title xxxxxxx</h2>
                <p class="text-md text-smcc-grey-dark">Laudem et dolorem? sunt autem quibusdam et negent satis esse, quam ostendis sed uti oratione perpetua malo quam ostendis sed quia non. Intellegamus, tu paulo ante cum soluta nobis est primum igitur, quid iudicat, quo pertineant non provident, similique.</p>
                <!-- <ion-list>
                    <ion-item>
                        <ion-label>- Privcay Preferences (cookies)</ion-label>
                        <button @click="getUser">Get User</button>
                        <button @click="handleSignOut()">Sign out</button>
                        <button @click="refresh()">Refresh</button>
                    </ion-item>
                </ion-list> -->

            </ion-card>
        </ion-content>
    </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonTitle, IonToolbar, IonContent, IonButton, IonCard, IonButtons, modalController } from '@ionic/vue';
import {useRouter} from 'vue-router';
import TutorialModal from '@/components/TutorialModal.vue';
export default {
    name: 'ExploreContainer',
    props: {
        name: String
    },
    components: { IonPage, IonHeader, IonTitle, IonToolbar, IonContent, IonButton, IonCard, IonButtons },
    data() {
        return {
            user: []
        }
    },
    setup() {
        const openModal = async (id, type) => {
        const modal = await modalController.create({
            component: TutorialModal, //Modal is name of the component to render inside ionic modal
            componentProps: {
                id: id,
                type: type
            },
            swipeToClose: true,
            backdropDismiss: true,
        });
            return modal.present();
        };
        const router = useRouter();
        return {
            router,
            openModal
        };
    },

}
</script>

<style scoped>
#container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

#container strong {
    font-size: 20px;
    line-height: 26px;
}

#container p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
}

#container a {
    text-decoration: none;
}

.button-inner{
    justify-content: space-between;
}
/*! purgecss start ignore */

/*! purgecss end ignore */
</style>
